@import '../../../styles/customMediaQueries.css';

.contentWidth {
  composes: contentWidth from global;
}

.sectionPadding {
  composes: sectionPadding from global;
}

.sectionHead {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }

  & h2 {
    font-weight: var(--fontWeightBold);
    letter-spacing: 0em;
    color: var(--colorGrey900);
    margin: 0 0 14px 0;
    padding: 0;
    font-size: 24px;
    line-height: 130%;

    @media (--viewportSmall) {
      font-size: 26px;
    }

    @media (--viewportMedium) {
      font-size: 28px;
    }

    @media (--viewportLarge) {
      font-size: 32px;
    }
  }

  & p {
    font-size: 16px;
    font-weight: var(--fontWeightMedium);
    line-height: 150%;
    letter-spacing: 0em;
    color: var(--colorGrey700);
    margin: 5px 0px;
    padding: 0;

    @media (--viewportSmall) {
      font-size: 18px;
    }
  }

  & > a {
    display: inline-block;
    color: var(--primaryColor);
    padding: 0px;
    transition: var(--transitionStyle);
    text-decoration: underline;
    font-size: 16px;
    line-height: 120%;
    font-weight: var(--fontWeightSemiBold);

    &:hover {
      transition: var(--transitionStyle);
      color: var(--marketplaceColorDark);
      text-decoration: underline;

      & > svg {
        & > path {
          fill: var(--marketplaceColorDark);
        }
      }
    }

    & > svg {
      margin-left: 2px;
      fill: none;
      width: 18px;
      height: 18px;
    }
  }
}

.tournamentSec {
  background-color: var(--colorWhite);

  & .contentWidth {
    display: flex;
    flex-direction: column;
  }

  & .sectionContent {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 24px;
    text-align: center;

    @media (--viewportSmall) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (--viewportMedium) {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  & .tournamentContent {
    & .tournamentImg {
      position: relative;

      & > img {
        max-width: 100%;
      }
    }

    & .tournamentInfo {
      & > h4 {
        color: var(--colorGrey900);
        margin: 0 0 14px 0;
      }
    }
  }

  & :global(.slick-slide) {
    &>div {
      padding: 14px;
    }
  }
  & :global(.slick-next) {
    right: 0 !important;
    @media(max-width:1024px) {
      right: 0;
    }
    &::before {
      content: "" !important;
      background-image: url("data:image/svg+xml,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22UTF-8%22%3F%3E%3C!--%20License%3A%20CC%20Attribution.%20Made%20by%20Mike%20Hunter%3A%20https%3A%2F%2Fcasinoshunter.com%2F%20--%3E%3Csvg%20width%3D%2230px%22%20height%3D%2230px%22%20viewBox%3D%22-5%200%2025%2025%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%3Ctitle%3Eright%3C%2Ftitle%3E%3Cdesc%3ECreated%20with%20Sketch.%3C%2Fdesc%3E%3Cg%20id%3D%22icons%22%20stroke%3D%22none%22%20stroke-width%3D%221%22%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%3Cg%20id%3D%22ui-gambling-website-lined-icnos-casinoshunter%22%20transform%3D%22translate(-1783.000000%2C%20-158.000000)%22%20fill%3D%22%237a7a7a%22%20fill-rule%3D%22nonzero%22%3E%3Cg%20id%3D%221%22%20transform%3D%22translate(1350.000000%2C%20120.000000)%22%3E%3Cpath%20d%3D%22M436.453517%2C38.569249%20L447.302459%2C48.9938158%20L447.39261%2C49.0748802%20C447.75534%2C49.423454%20447.968159%2C49.8870461%20448%2C50.4382227%20L447.998135%2C50.6228229%20C447.968159%2C51.1129539%20447.75534%2C51.576546%20447.333675%2C51.9774469%20L447.339095%2C51.9689832%20L436.453517%2C62.430751%20C435.663694%2C63.1897497%20434.399001%2C63.1897497%20433.609178%2C62.430751%20C432.796941%2C61.650213%20432.796941%2C60.3675924%20433.609432%2C59.5868106%20L443.012324%2C50.5572471%20L433.609178%2C41.4129456%20C432.796941%2C40.6324076%20432.796941%2C39.349787%20433.609178%2C38.569249%20C434.399001%2C37.8102503%20435.663694%2C37.8102503%20436.453517%2C38.569249%20Z%22%20id%3D%22right%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E");
      width: 30px;
      height: 30px;
      position: absolute;
    }
  }
  & :global(.slick-prev) {
    z-index: 1 !important;
    left: -34px !important;
    @media(max-width:1024px) {
      left: 0;
    }
    &::before {
      content: "" !important;
      background-image: url("data:image/svg+xml,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22UTF-8%22%3F%3E%3C!--%20License%3A%20CC%20Attribution.%20Made%20by%20Mike%20Hunter%3A%20https%3A%2F%2Fcasinoshunter.com%2F%20--%3E%3Csvg%20width%3D%2230px%22%20height%3D%2230px%22%20viewBox%3D%22-5%200%2025%2025%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%3Ctitle%3Eleft%3C%2Ftitle%3E%3Cdesc%3ECreated%20with%20Sketch.%3C%2Fdesc%3E%3Cg%20id%3D%22icons%22%20stroke%3D%22none%22%20stroke-width%3D%221%22%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%3Cg%20id%3D%22ui-gambling-website-lined-icnos-casinoshunter%22%20transform%3D%22translate(-1913.000000%2C%20-158.000000)%22%20fill%3D%22%237a7a7a%22%20fill-rule%3D%22nonzero%22%3E%3Cg%20id%3D%221%22%20transform%3D%22translate(1350.000000%2C%20120.000000)%22%3E%3Cpath%20d%3D%22M566.453517%2C38.569249%20L577.302459%2C48.9938158%20L577.39261%2C49.0748802%20C577.75534%2C49.423454%20577.968159%2C49.8870461%20578%2C50.4382227%20L577.998135%2C50.6228229%20C577.968159%2C51.1129539%20577.75534%2C51.576546%20577.333675%2C51.9774469%20L577.339095%2C51.9689832%20L566.453517%2C62.430751%20C565.663694%2C63.1897497%20564.399001%2C63.1897497%20563.609178%2C62.430751%20C562.796941%2C61.650213%20562.796941%2C60.3675924%20563.609432%2C59.5868106%20L573.012324%2C50.5572471%20L563.609178%2C41.4129456%20C562.796941%2C40.6324076%20562.796941%2C39.349787%20563.609178%2C38.569249%20C564.399001%2C37.8102503%20565.663694%2C37.8102503%20566.453517%2C38.569249%20Z%22%20id%3D%22left%22%20transform%3D%22translate(570.500000%2C%2050.500000)%20scale(-1%2C%201)%20translate(-570.500000%2C%20-50.500000)%20%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E");
      width: 32px;
      height: 32px;
      position: absolute;
    }
  }
}

.aboutTournaZoneSec {
  position: relative;
  padding-bottom: 56.25%;
  margin-top: 14px;

  & > iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.tournamentRegisterSec {
  & .contentWidth {
    display: flex;
    flex-direction: column;
  }

  & .sectionContent {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 24px;

    @media (--viewportSmall) {
      grid-template-columns: repeat(1, 1fr);
    }

    @media (--viewportMedium) {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  & .tournamentContent {
    & .tournamentImg {
      position: relative;

      & > img {
        max-width: 100%;
      }
    }

    & .tournamentInfo {
      display: flex;
      flex-direction: column;

      & > h4 {
        color: var(--colorGrey900);
        margin: 10px 0;
      }

      & > p {
        line-height: 160%;
        color: var(--colorGrey600);
      }
    }
  }
}

.freeRegistrationSec {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;

  & > button {
    width: auto;
    margin: 0 auto;
  }
}

.tradingPlatformSec {
  background-image: url('https://sharetribe-assets.imgix.net/6565a2b4-7a23-4278-841c-7684a015c012/raw/4e/1d8e8bc3b768e6c4041a0c18b898bba81beba3?auto=format&fit=clip&h=2400&w=2400&s=b828bf9c77764d7327b9bf0b553b72dd');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  & .contentWidth {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;

    @media (--viewportMedium) {
      flex-direction: row;
      gap: 64px;
    }

    & .tradingInfo {
      width: 100%;
      order: 2;

      @media (--viewportMedium) {
        width: 50%;
        order: 1;
      }

      & h4 {
        color: var(--colorGrey900);
      }

      & .link {
        width: auto;
        margin-top: 24px;
      }
    }

    & .tradingImg {
      width: 100%;
      border-radius: var(--borderRadiusLarge);
      overflow: hidden;
      order: 1;
      padding-bottom: 66%;
      position: relative;

      @media (--viewportMedium) {
        padding-bottom: 40%;
        width: 50%;
        order: 2;
      }

      & > img {
        max-width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

.tournamentInfo {
  & span {
    cursor: pointer;
    color: var(--marketplaceColor);
  }
}
