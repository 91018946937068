@import '../../../../styles/customMediaQueries.css';

.heroSearchFld {
  width: calc(100% / 1);
  padding: 4px 8px;
  @media (--viewportMedium) {
    width: calc(100% / 3);
  }
  @media (--viewportLarge) {
    width: calc(100% / 3);
  }
  & > label {
    color: var(--colorGrey900);
    font-size: 15px;
    line-height: 100%;
    font-weight: var(--fontWeightSemiBold);
    padding: 0;
    margin: 0 0 5px 0;
    text-align: left;
  }

  & input[type='checkbox'] {
    + label {
      margin: 0;
      padding: 0;

      & > span {
        & > svg {
          & path {
            fill: var(--colorGrey900);
            stroke: none;
            stroke-width: initial;
          }
        }

        &:not(:first-child) {
          color: var(--colorGrey900);
          font-size: 14px;
          line-height: 100%;
          font-weight: var(--fontWeightSemiBold);
          padding: 0;
          margin: 0;
          text-align: left;
        }
      }
    }
  }

  & input[type='checkbox']:checked,
  & input[type='checkbox']:hover {
    + label {
      & > span {
        & > svg {
          & path {
            fill: var(--colorGrey900);
            stroke: none;
            stroke-width: initial;
          }
        }
      }
    }
  }
}

.heroSearchBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: none;
  max-height: 50px;

  & > svg {
    margin: 0px !important;
  }
}

.checkboxLabel {
  color: var(--colorGrey900);
  font-size: 15px;
  line-height: 100%;
  font-weight: var(--fontWeightSemiBold);
  padding: 0;
  margin: 0 0 5px 0;
  text-align: left;
}

.clearButton {
  cursor: pointer;
  margin-top: 10px;
  font-size: 15px;
}

.searchButtonWrapper {
  position: relative;
  margin-top: 20px;
  order: 2;
  @media (--viewportMedium) {
    order: 1;
  }
}

.tournamentDetails {
  padding: 4px 8px;
  width: 100%;
  position: relative;
  order: 1;
  @media (--viewportMedium) {
    flex-grow: 0.3;
    width: calc(100% / 2);
    margin-top: -30px;
    order: 2;
  }
  & .tournamentDetailList {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 16px;
    @media (--viewportMedium) {
      grid-template-columns: repeat(4, 1fr);
    }
    & .heroSearchFld {
      width: 100%;
      padding: 0;
    }
  }
}
.optionDisabled {
  &:disabled {
font-weight: 600;
font-style: italic;
font-size: 17px;
margin-top: 6px;
  }
}